<!-- eslint-disable max-len -->
<template>
<svg
    width="96" height="100" viewBox="0 0 96 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M21.4044 0.331353C11.4454 2.11197 3.4326 9.48289 0.906616 19.1727C-0.00439578 22.6719 -0.0665102 24.7216 0.0370139 51.8657C0.0991283 76.7943 0.119833 77.374 0.53393 79.2996C2.83216 89.6727 10.2031 97.0643 20.6797 99.5075C22.0462 99.818 23.7854 99.8801 34.9868 99.9423C46.5815 100.025 47.7823 100.004 48.0929 99.6938C48.4035 99.3832 48.4242 95.3872 48.4242 50.085C48.4242 16.5018 48.3621 0.683335 48.2171 0.393468C48.0101 0.0207807 47.6581 7.58968e-05 35.5251 0.0207807C25.6696 0.0414855 22.7088 0.1036 21.4044 0.331353ZM40.1423 50.0229V91.9709L31.7154 91.8674C23.9511 91.7846 23.1229 91.7431 21.57 91.3498C14.9031 89.6313 9.95462 84.4965 8.5674 77.8088C8.11189 75.7176 8.11189 24.204 8.5467 22.1542C9.78899 16.3362 13.8264 11.512 19.2718 9.33795C22.0048 8.2406 23.2678 8.11637 32.1295 8.09566L40.1423 8.07496V50.0229Z" fill="currentColor" />
    <path d="M23.185 20.7256C21.8806 20.9741 19.8929 21.9679 18.837 22.8996C16.663 24.7838 15.5863 27.4547 15.752 30.6018C15.8348 32.2375 15.9383 32.6723 16.6009 33.9974C17.574 36.0058 19.044 37.4758 21.0524 38.4697C22.4396 39.1529 22.7916 39.2357 24.5929 39.2979C26.2286 39.36 26.8083 39.2979 27.9057 38.9252C32.3986 37.4137 35.111 33.045 34.3449 28.5728C33.4546 23.2516 28.4026 19.6904 23.185 20.7256Z" fill="currentColor" />
    <path d="M53.5382 0.144937C53.4554 0.207052 53.3933 22.6511 53.3933 50.0229C53.3933 95.1801 53.414 99.7558 53.7246 99.8801C54.2836 100.087 70.3506 100.004 72.3382 99.7972C80.7444 98.8448 88.1567 93.7307 92.1941 86.1321C92.7118 85.1589 93.395 83.5233 93.747 82.5087C95.0514 78.6162 95.01 79.6515 95.01 49.8986C95.01 26.1502 94.9686 22.6097 94.6787 21.0982C92.6289 10.311 84.4091 2.17401 73.6012 0.310576C72.1519 0.0621179 69.9158 3.42565e-06 62.7105 3.42565e-06C57.7413 3.42565e-06 53.6004 0.0621179 53.5382 0.144937ZM75.651 45.24C78.8809 46.0889 81.5312 48.5528 82.6078 51.6999C83.2911 53.6462 83.2704 56.5035 82.5871 58.2841C81.3241 61.5554 78.8395 63.8537 75.651 64.7026C70.4748 66.0484 64.9673 62.922 63.4559 57.7872C63.0003 56.2136 63.0211 53.5634 63.5387 51.9484C65.0915 46.8757 70.4748 43.8942 75.651 45.24Z" fill="currentColor" />
</svg>
</template>
