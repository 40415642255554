<!-- eslint-disable max-len -->
<template>
<svg
    width="91" height="100" viewBox="0 0 91 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M58.6052 45.7707L15.4913 2.53956L70.3458 34.0301L58.6052 45.7707ZM4.23911 0C1.69955 1.32838 0 3.75073 0 6.89588V93.1041C0 96.2493 1.69955 98.6716 4.23911 100L54.3661 49.9902L4.23911 0ZM87.3022 44.0711L75.7961 37.4097L62.9615 50.0098L75.7961 62.6099L87.5366 55.9484C91.0529 53.1549 91.0529 46.8646 87.3022 44.0711ZM15.4913 97.48L70.3458 65.9895L58.6052 54.2489L15.4913 97.48Z" fill="currentColor" />
</svg>
</template>
