<!-- eslint-disable max-len -->
<template>
<svg
    width="19" height="17" viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path id="Vector" d="M16.6849 5.26651C16.6849 6.67448 15.5328 7.80751 14.1293 7.80751C12.7132 7.80751 11.5737 6.67031 11.5737 5.26651C11.5737 3.86271 12.7174 2.7255 14.1293 2.7255C15.5328 2.7255 16.6849 3.85854 16.6849 5.26651ZM18.8845 5.27484C18.8845 7.89916 16.7478 10.0153 14.1209 10.0153L9.54175 13.3394C9.37416 15.1306 7.84497 16.5386 6.00157 16.5386C4.3048 16.5386 2.87197 15.3389 2.52423 13.7476L0.115234 12.7854V8.31571L4.18749 9.95279C4.82011 9.56956 5.53653 9.39877 6.36606 9.47375L9.34065 5.23735C9.3616 2.64219 11.4983 0.538574 14.1167 0.538574C16.7478 0.538574 18.8845 2.66302 18.8845 5.27484ZM8.62004 12.9937C8.62004 11.5482 7.45534 10.3902 6.00157 10.3902C5.81304 10.3902 5.62451 10.411 5.43598 10.4527L6.52526 10.89C7.5936 11.3149 8.1173 12.5146 7.68577 13.5768C7.25843 14.6391 6.04346 15.1598 4.97512 14.7224C4.54779 14.5558 4.11626 14.3766 3.68893 14.2142C4.12883 15.0348 4.99607 15.5972 6.00157 15.5972C7.45534 15.5972 8.62004 14.4391 8.62004 12.9937ZM17.3134 5.27484C17.3134 3.5253 15.8764 2.10067 14.1209 2.10067C12.3488 2.10067 10.9159 3.5253 10.9159 5.27484C10.9159 7.03272 12.3529 8.44901 14.1209 8.44901C15.8764 8.45318 17.3134 7.03688 17.3134 5.27484Z" fill="currentColor" />
</svg>
</template>
