<!-- eslint-disable max-len -->
<template>
<svg
    width="100" height="100" viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M57.4106 29.2126C46.5401 29.6137 19.5758 32.666 19.5758 55.3917C19.5758 79.7884 50.4463 80.791 60.5356 65.0167C61.9865 67.2893 68.4374 73.3718 70.6472 75.4438L83.3258 62.967C83.3258 62.967 76.116 57.3301 76.116 51.2031V18.429C76.116 12.7922 70.6472 0.0925293 51.049 0.0925293C31.4061 0.0925293 20.982 12.3466 20.982 23.3307L37.3883 24.8457C41.0267 13.8171 49.4865 13.8171 49.4865 13.8171C58.5713 13.7948 57.4106 20.4565 57.4106 29.2126ZM57.4106 48.5517C57.4106 66.3758 38.616 63.7022 38.616 52.3839C38.616 41.8677 49.8883 39.7511 57.4106 39.506V48.5517ZM87.7678 84.9797C86.049 87.2077 72.1428 99.9073 48.8169 99.9073C25.491 99.9073 7.63382 83.9771 2.16507 77.4045C0.647213 75.6889 2.38829 74.8868 3.39275 75.5552C19.7544 85.4698 45.3124 101.801 86.5401 82.3061C88.2142 81.4817 89.5088 82.7517 87.7678 84.9797ZM96.6517 85.4698C95.2008 88.9901 93.0802 91.4409 91.9195 92.3767C90.6919 93.3793 89.799 92.9782 90.4686 91.53C91.1383 90.0818 94.7767 81.1698 93.3035 79.276C91.8526 77.4267 85.0445 78.3179 82.5892 78.563C80.1785 78.7858 79.6874 79.0086 79.4642 78.4962C78.9508 77.2262 84.3079 75.0428 87.8347 74.5972C91.3392 74.1961 96.9865 74.4189 98.1026 75.8671C98.9285 77.0034 98.1026 81.905 96.6517 85.4698Z" fill="currentColor" />
</svg>
</template>
