<!-- eslint-disable max-len -->
<template>
<svg
    width="75" height="100" viewBox="0 0 75 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M62.2461 52.4805C62.207 45.3125 65.4492 39.9023 72.0117 35.918C68.3398 30.6641 62.793 27.7734 55.4688 27.207C48.5352 26.6602 40.957 31.25 38.1836 31.25C35.2539 31.25 28.5352 27.4023 23.2617 27.4023C12.3633 27.5781 0.78125 36.0938 0.78125 53.418C0.78125 58.5352 1.71875 63.8216 3.59375 69.2773C6.09375 76.4453 15.1172 94.0234 24.5312 93.7305C29.4531 93.6133 32.9297 90.2344 39.3359 90.2344C45.5469 90.2344 48.7695 93.7305 54.2578 93.7305C63.75 93.5937 71.9141 77.6172 74.2969 70.4297C61.5625 64.4336 62.2461 52.8516 62.2461 52.4805ZM51.1914 20.4102C56.5234 14.082 56.0352 8.32031 55.8789 6.25C51.1719 6.52344 45.7227 9.45313 42.6172 13.0664C39.1992 16.9336 37.1875 21.7188 37.6172 27.1094C42.7148 27.5 47.3633 24.8828 51.1914 20.4102Z" fill="currentColor" />
</svg>
</template>
