<!-- eslint-disable max-len -->
<template>
<svg
    width="114" height="100" viewBox="0 0 114 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M56.3961 9.0156L59.5465 3.57046C61.4912 0.16725 65.8278 -0.98012 69.2311 0.964573C72.6343 2.90927 73.7816 7.24593 71.8369 10.6491L41.4803 63.1947H63.4359C70.5534 63.1947 74.5401 71.5569 71.448 77.3521H7.07868C3.1504 77.3521 0 74.2017 0 70.2734C0 66.3451 3.1504 63.1947 7.07868 63.1947H25.1254L48.2284 23.1535L41.0136 10.6297C39.0689 7.22649 40.2162 2.92871 43.6195 0.945127C47.0227 -0.999565 51.3204 0.147802 53.304 3.55101L56.3961 9.0156ZM29.0926 84.6447L22.2862 96.449C20.3415 99.8522 16.0048 101 12.6016 99.0549C9.1984 97.1102 8.05103 92.7735 9.99572 89.3703L15.0519 80.6192C20.7693 78.8495 25.4171 80.2108 29.0926 84.6447ZM87.7056 63.2336H106.122C110.05 63.2336 113.201 66.384 113.201 70.3123C113.201 74.2406 110.05 77.391 106.122 77.391H95.8928L102.796 89.3703C104.741 92.7735 103.594 97.0713 100.191 99.0549C96.7874 101 92.4896 99.8522 90.506 96.449C78.8767 76.2825 70.1451 61.1917 64.3499 51.1376C58.4186 40.9086 62.658 30.6406 66.8391 27.1596C71.4869 35.1328 78.4294 47.1705 87.7056 63.2336Z" fill="currentColor" />
</svg>
</template>
