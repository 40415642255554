<template>
<AmazonSVG v-if="platform == 'amazon'" :class="['icon', ...classes]" :alt="alt" />
<AppleSVG v-if="platform == 'apple'" :class="['icon', ...classes]" :alt="alt" />
<EpicGamesSVG v-if="platform == 'egs'" :class="['icon', ...classes]" :alt="alt" />
<GoogleSVG v-if="platform == 'google'" :class="['icon', ...classes]" :alt="alt" />
<HumbleSVG v-if="platform == 'humble'" :class="['icon', ...classes]" :alt="alt" />
<LunaSVG v-if="platform == 'luna'" :class="['icon', ...classes]" :alt="alt" />
<MacAppStoreSVG v-if="platform == 'mas'" :class="['icon', ...classes]" :alt="alt" />
<NintendoSwitchSVG v-if="platform == 'nintendo-switch'" :class="['icon', ...classes]" :alt="alt" />
<PlaystationSVG v-if="platform == 'ps'" :class="['icon', ...classes]" :alt="alt" />
<SteamSVG v-if="platform == 'steam'" :class="['icon', ...classes]" :alt="alt" />
<XboxSVG v-if="platform == 'xbox'" :class="['icon', ...classes]" :alt="alt" />
</template>

<script lang="ts">
import AmazonSVG from 'public/images/icons/platforms/amazon.svg.vue'
import AppleSVG from 'public/images/icons/platforms/apple.svg.vue'
import EpicGamesSVG from 'public/images/icons/platforms/egs.svg.vue'
import GoogleSVG from 'public/images/icons/platforms/google.svg.vue'
import HumbleSVG from 'public/images/icons/platforms/humble.svg.vue'
import LunaSVG from 'public/images/icons/platforms/luna.svg.vue'
import MacAppStoreSVG from 'public/images/icons/platforms/mas.svg.vue'
import NintendoSwitchSVG from 'public/images/icons/platforms/nintendo-switch.svg.vue'
import PlaystationSVG from 'public/images/icons/platforms/ps.svg.vue'
import SteamSVG from 'public/images/icons/platforms/steam.svg.vue'
import XboxSVG from 'public/images/icons/platforms/xbox.svg.vue'

export default defineNuxtComponent({
    components: {
        AmazonSVG,
        AppleSVG,
        EpicGamesSVG,
        GoogleSVG,
        HumbleSVG,
        LunaSVG,
        MacAppStoreSVG,
        NintendoSwitchSVG,
        PlaystationSVG,
        SteamSVG,
        XboxSVG
    },
    props: {
        platform: {
            type: String,
            required: true
        },
        iconColor: {
            type: String,
            default: 'currentColor'
        },
        classes: {
            type: Array,
            default: []
        },
        alt: {
            type: String,
            default: ''
        }
    }
})
</script>

<style scoped lang="scss">
    .icon {
        color: v-bind(iconColor);
        width: 100%;
        height: 100%;
    }
</style>
