<!-- eslint-disable max-len -->
<template>
<svg
    width="102" height="100" viewBox="0 0 102 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M44.4442 0.483142C48.6407 -0.612118 53.3075 0.167659 56.8492 2.69152C61.1945 5.70348 63.7601 11.15 63.0934 16.4299C62.3731 23.6384 62.2124 31.5671 66.2185 37.9303C68.7185 42.1923 71.165 46.49 73.6472 50.7639C76.8377 56.4128 82.2604 60.7284 88.451 62.6451C93.475 64.1511 97.7905 67.8535 100.029 72.5977C103.154 78.9966 102.308 87.1099 97.8679 92.6933C93.0345 99.0446 84.0581 101.61 76.552 98.9732C72.6114 97.7589 69.7959 94.5326 66.0637 92.9076C55.2897 87.5504 42.8846 87.3837 31.1523 88.3718C24.8486 88.8421 18.8723 91.0445 12.8781 92.8838C8.50895 94.11 3.51481 91.9612 1.33024 88.0028C-1.55673 83.1277 0.431407 76.0978 5.59223 73.6334C10.3185 71.1096 13.5329 66.7047 16.2174 62.2046C21.1104 53.7878 25.9438 45.3412 30.8189 36.9184C33.6404 32.0076 33.8368 26.1563 33.6999 20.6383C33.6821 17.8525 32.8725 15.0608 33.4976 12.2929C34.4202 6.66184 38.9143 1.84031 44.4442 0.483142ZM44.5275 35.2874C41.2715 36.5255 39.4322 39.728 37.4917 42.4126C32.688 49.5853 28.4915 57.1688 24.9557 65.044C22.9616 69.6214 25.0152 75.4132 29.3844 77.7823C32.2832 79.3716 35.694 79.2288 38.8905 79.574C47.2121 80.0264 55.6051 80.0205 63.8791 78.9311C68.2899 78.3299 71.7126 74.3834 72.2602 70.0619C72.7067 67.2166 71.3912 64.5201 70.1292 62.0558C67.8554 57.139 65.0934 52.4663 62.385 47.7817C60.2124 44.1031 57.9683 40.3887 54.9504 37.3291C52.2956 34.6029 48.0157 34.1564 44.5275 35.2874Z" fill="currentColor" />
</svg>
</template>
