<!-- eslint-disable max-len -->
<template>
<svg
    width="100" height="100" viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M72.2462 62.1484C80.8985 72.7539 84.8829 81.4453 82.8712 85.332C81.3282 88.2812 71.7969 94.043 64.7852 96.25C59.004 98.0664 51.4258 98.8477 45.1758 98.2422C37.7149 97.5195 30.1563 94.8437 23.6719 90.625C18.2227 87.0703 16.9922 85.6055 16.9922 82.6953C16.9922 76.8555 23.418 66.6211 34.4141 54.9414C40.6641 48.3203 49.3555 40.5469 50.3126 40.7617C52.1485 41.1719 66.7774 55.4297 72.2462 62.1484ZM36.836 28.0859C31.0352 22.832 25.4883 17.5586 19.961 15.7031C16.9922 14.707 16.7774 14.7656 14.3555 17.2852C8.65241 23.2227 3.90631 32.8516 2.57819 41.1914C1.5235 47.8711 1.38678 49.7461 1.75787 53.0078C2.85162 62.8711 5.13678 69.6875 9.66803 76.6211C11.5235 79.4727 12.0313 80 11.4844 78.5547C10.6641 76.4062 11.4258 71.2305 13.3399 66.0547C16.1329 58.4375 23.8673 44.0039 36.836 28.0859ZM97.6954 40.4883C94.3946 24.8633 84.5118 15.0391 83.1251 15.0391C81.6993 15.0391 78.3985 16.3086 76.0938 17.7539C71.543 20.5859 68.086 23.8867 63.5352 28.0664C71.8165 38.4766 83.4962 55.293 87.5391 67.5781C88.8673 71.6211 89.4337 75.6055 88.9844 77.793C88.6524 79.4531 88.6524 79.4531 89.2579 78.6914C90.4493 77.1875 93.1446 72.5781 94.2188 70.1953C95.6641 67.0312 97.1485 62.3437 97.8516 58.7305C98.6915 54.3359 98.6133 44.9023 97.6954 40.4883ZM27.5977 8.39843C36.9141 7.91015 49.0235 15.1367 49.9219 15.3125C50.0587 15.332 51.9532 14.4922 54.1407 13.418C66.6212 7.34375 72.5001 8.3789 75.1173 8.49609C62.6368 0.820309 45.293 -1.26954 29.4337 6.21093C24.8633 8.3789 24.7462 8.53515 27.5977 8.39843Z" fill="currentColor" />
</svg>
</template>
