<!-- eslint-disable max-len -->
<template>
<svg
    width="113" height="87" viewBox="0 0 113 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clip-path="url(#clip0_54_2436)">
        <path d="M112.123 66.7148C109.904 69.4883 104.503 71.4609 104.503 71.4609L64.2219 85.8359V75.2305L93.8583 64.7227C97.2167 63.5313 97.747 61.832 94.9974 60.9336C92.2675 60.0352 87.3182 60.2891 83.9598 61.5L64.2219 68.4336V57.418C68.7783 55.8945 73.4722 54.7617 79.0892 54.1367C87.1218 53.2578 96.9417 54.2539 104.66 57.1641C113.341 59.8984 114.323 63.9414 112.123 66.7148ZM68.0516 48.6484V21.5C68.0516 18.3164 67.4624 15.3867 64.4576 14.5469C62.1597 13.8047 60.726 15.9336 60.726 19.1172V87.0664L42.3039 81.2461V0.25C50.1402 1.69531 61.5509 5.11328 67.6785 7.16406C83.2921 12.4961 88.5752 19.1367 88.5752 34.0781C88.5752 48.6289 79.5409 54.1562 68.0516 48.6484ZM8.48425 74.1172C-0.432196 71.6172 -1.92482 66.4023 2.14061 63.4141C5.89181 60.6406 12.2944 58.5508 12.2944 58.5508L38.7098 49.2148V59.8594L19.6986 66.6172C16.3402 67.8086 15.8295 69.5078 18.5594 70.4063C21.2894 71.3047 26.2386 71.0508 29.597 69.8398L38.7098 66.5391V76.0703C28.5757 77.8867 18.7951 77.4961 8.48425 74.1172Z" fill="currentColor" />
    </g>
    <defs>
        <clipPath id="clip0_54_2436">
            <rect width="113" height="87" fill="white" />
        </clipPath>
    </defs>
</svg>
</template>
